@import '../../../scss/utilities';

.button {
  $padding-x: 2rem;
  $padding-y: 1rem;
  $font-size: $ue-font-size-9;
  $border-radius: 1rem;

  padding: $padding-y $padding-x;
  line-height: $btn-line-height;
  font-size: $font-size;
  color: $ue-white;
  text-transform: uppercase;
  font-family: $ue-subheader-font;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  white-space: $btn-white-space;
  vertical-align: middle;
  display: inline-block;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: $border-radius;
  // stylelint-disable plugin/no-unsupported-browser-features
  // most browsers implement fine, not breaking when they don't implement
  user-select: none;
  // stylelint-enable plugin/no-unsupported-browser-features
  transition: $btn-transition;
  &:hover {
    background-color: $ue-dark-gray;
    color: $ue-white;
    border-color: $ue-dark-gray;
    text-decoration: none;
  }
  &:focus {
    // stylelint-disable plugin/no-unsupported-browser-features
    // IE supports this
    outline: 0;
    // stylelint-enable plugin/no-unsupported-browser-features
    // focus styles are not optional
    box-shadow: 0px 0px 10px $ue-shadow;
    text-decoration: none;
  }
  &:disabled {
    color: $ue-light-gray;
    background-color: transparent;
    border-color: $ue-light-gray;
  }
  &:not(:disabled) {
    cursor: pointer;
    &:active,
    &.active {
      background-color: $ue-black;
      border-color: $ue-black;
    }
  }

  // Brand Categories, filled
  @each $category, $color in $category-colors {
    &--#{$category} {
      background-color: $color;
    }
  }
  // Bootstrap themes, filled
  @each $color, $value in $theme-colors {
    &--#{$color} {
      background-color: $value;
      @if $color == light {
        background-color: $ue-white;
        box-shadow: 0px 0px 15px $ue-shadow;
        color: $ue-black;
      }
    }
  }

  // Brand Categories, outlined
  @each $category, $color in $category-colors {
    &--outline-#{$category} {
      color: $color;
      border-color: $color;
    }
  }
  // Bootstrap themes, outlined
  @each $color, $value in $theme-colors {
    &--outline-#{$color} {
      color: $value;
      border-color: $value;
      @if $color == light {
        border-color: $ue-white;
        color: $ue-white;
      }
    }
  }
}
